@import url("https://fonts.googleapis.com/css?family=Josefin+Sans|Kanit");

.container {
  display: flex;
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
}
