.hero {
    flex: 2;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 100px;

    .title,
    .subtitle {
    font-family: "Josefin Sans", sans-serif;
    }

    .title {
    font-size: 2.5em;
    }

    .subtitle {
    color: #666;
    }

    .logo {
    width: 300px;
    height: 300px;
    border-radius: 150px;
    }
}
