.stream-list {
    flex: 1;
    display: flex;
    flex-direction: column;

    .stream-list-title {
        font-family: "Josefin Sans", sans-serif;
        font-size: 30px;
        margin: 0 5px 20px;
        text-align: center;
        padding: 20px 10px 5px;
        border-bottom: 1px solid #e3e3e3;
    }

    a {
        text-decoration: none;

        &:hover .stream-title {
            text-decoration: underline;
        }
    }

    .stream {
        display: flex;
        flex-direction: row;
        padding: 5px;
        margin-bottom: 15px;

        .stream-image {
            width: 60px;
            height: 60px;
            border-radius: 30px;
        }

        .stream-info {
            padding-left: 10px;
            font-family: "Josefin Sans", sans-serif;
            color: black;
        }

        .stream-title {
            margin: 0;
            font-size: 16px;
            line-height: 20px;
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .stream-extras {
            margin: 0;
            margin-top: 6px;
            font-size: 14px;
        }
    }
}
