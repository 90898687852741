@import "https://fonts.googleapis.com/css?family=Josefin+Sans|Kanit";
.navigation {
  flex: 1;
}

.hero {
  text-align: center;
  flex: 2;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.hero .title, .hero .subtitle {
  font-family: Josefin Sans, sans-serif;
}

.hero .title {
  font-size: 2.5em;
}

.hero .subtitle {
  color: #666;
}

.hero .logo {
  width: 300px;
  height: 300px;
  border-radius: 150px;
}

.stream-list {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.stream-list .stream-list-title {
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  margin: 0 5px 20px;
  padding: 20px 10px 5px;
  font-family: Josefin Sans, sans-serif;
  font-size: 30px;
}

.stream-list a {
  text-decoration: none;
}

.stream-list a:hover .stream-title {
  text-decoration: underline;
}

.stream-list .stream {
  flex-direction: row;
  margin-bottom: 15px;
  padding: 5px;
  display: flex;
}

.stream-list .stream .stream-image {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

.stream-list .stream .stream-info {
  color: #000;
  padding-left: 10px;
  font-family: Josefin Sans, sans-serif;
}

.stream-list .stream .stream-title {
  height: 40px;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
}

.stream-list .stream .stream-extras {
  margin: 6px 0 0;
  font-size: 14px;
}

.container {
  display: flex;
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
}

/*# sourceMappingURL=index.95063c9e.css.map */
